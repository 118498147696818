import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import image4 from "../assets/nowah-bartscher-n84u5XBB9GI-unsplash.jpg"

export const testimonialsData = [
  
  {
    image: image1,
    review:
      "Had a fantastic experience. Staff were very informative and guided thoroughly. As I have long hair & doing any kind of treatment is very time consuming and hectic but still he was very patient and did it with a smiling face. Did Nanoplastia and my hair is looking awesome & the feel of it is also awesome. Thank you Keep up the good job & best of luck!",
    name: 'Sutithi Das',
    status : 'Customer'
  },
  {
    image : image4,
    review:`The staff is friendly, warm, and cooperative. They greet me with happy faces all the time. They take care of your everything they will keep asking if customer is comfortable. Customer service - 5 star Ambience - 5 star I did Hair SPA my hair looks flawless. Detan was very good Everything was wonderful and perfect to cheer up your mood. Everyone please visit TGF family salon you will not regret.`,
    name: 'Shaina Tabassum',
    status: "Customer"
  },
  {
    image: image2,
    review: `Its an wonderful experience for me in TGF and I will definately visit again ....and all the services are done with utmost care... Thank you`,
    name: 'Ankita Saha',
    status: 'Customer'
  },
  {
    image : image3,
    review:`All the members are very kind and humble .. Their service are excellent.. They actually take time to make me look more beautiful ..I will suggest everyone to visit The TGF salon..`,
    name: 'Anushka Karmakar',
    status: "Customer"
  }
  
];
