export const programsData = [
  {
    image: <img src="https://i.ibb.co/0n7C6Qq/michael-demoya-Q82-AM6-BWBPM-unsplash.jpg" alt="haircut" width="305" height="250" />,
    heading: "Haircut",
    // details: "In this program, you are trained to improve your strength through many exercises.",
  },
  {
    image: <img src="https://i.ibb.co/kgGRGRW/arthur-humeau-Twd3yaq-A2-NM-unsplash.jpg" alt="beard trimming" width="305" height="250" />,
    heading: "Beard Trimming",
    // details: "In this program, you are trained to do sequential moves in range of 20 until 30 minutes.",
  },
  {
    image: <img src="https://i.ibb.co/rtVsWzQ/lindsay-cash-Md-Dha-Fsn-CQ-unsplash.jpg" alt="Hair Colouring" width="305" height="250" />,
    heading: "Hair Colouring",
    // details: "This program is suitable for you who wants to get rid of your fat and lose their weight.",
  },
  {
    image: <img src="https://i.ibb.co/Tv8hKwM/stefan-schauberger-Tper6b-He-SUo-unsplash.jpg" alt="Spa services" width="305" height="250" />,
    heading: "Spa services",
    // details: "This programs is designed for those who exercises only for their body fitness not body building.",
  },
  {
    image: <img src='https://i.ibb.co/HtHJ9Ds/engin-akyurt-g-m8-EDc4-X6-Q-unsplash-1.jpg' alt="Spa services" width="305" height="250" />,
    heading: "Facial services",
    // details: "This programs is designed for those who exercises only for their body fitness not body building.",
  },
  {
    image: <img src='https://i.ibb.co/d6QxDMk/no-revisions-DYh-TXr-J7he0-unsplash.jpg' alt="Spa services" width="305" height="250" />,
    heading: "Waxing",
    // details: "This programs is designed for those who exercises only for their body fitness not body building.",
  },
  {
    image: <img src='https://i.ibb.co/xHSDvr2/rune-enstad-qeu-Jcz-No54w-unsplash.jpg' alt="Spa services" width="305" height="250" />,
    heading: "Pedicure Manicure",
    // details: "This programs is designed for those who exercises only for their body fitness not body building.",
  },
  {
    image: <img src='https://i.ibb.co/Bgs54b5/shari-sirotnak-p7g3-Ndbe-DBM-unsplash.jpg' alt="Spa services" width="305" height="250" />,
    heading: "D-Tan",
    // details: "This programs is designed for those who exercises only for their body fitness not body building.",
  },
  {
    image: <img src='https://i.ibb.co/gPL8GZN/rosa-rafael-Pe9-IXUu-C6-QU-unsplash.jpg' alt="Spa services" width="305" height="250" />,
    heading: "Botox",
    // details: "This programs is designed for those who exercises only for their body fitness not body building.",
  },
  {
    image: <img src='https://i.ibb.co/j8WkYrX/kris-atomic-Xa8f-X8b-QCgs-unsplash.jpg' alt="Spa services" width="305" height="250" />,
    heading: "Nails",
    // details: "This programs is designed for those who exercises only for their body fitness not body building.",
  },
];
