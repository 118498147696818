import React, { useState, useEffect, useRef } from 'react';
import './Testimonials.css';
import { testimonialsData } from '../../data/testimonialsData';
import leftArrow from '../../assets/leftArrow.png';
import rightArrow from '../../assets/rightArrow.png';
import { motion } from 'framer-motion';

const Testimonials = () => {
    const [selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;
    const intervalRef = useRef(null);

    // Function to start or restart the interval
    const startAutoSlide = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            setSelected(prev => (prev + 1) % tLength);
        }, 8000); // 8 seconds interval
    };

    // Start the interval on component mount
    useEffect(() => {
        startAutoSlide();
        return () => clearInterval(intervalRef.current);
    }, [tLength]);

    // Reset interval on manual review change
    const handleManualChange = (direction) => {
        setSelected(prev => {
            const newIndex = direction === 'next' 
                ? (prev + 1) % tLength 
                : prev === 0 
                ? tLength - 1 
                : prev - 1;
            return newIndex;
        });
        startAutoSlide(); // Restart the interval
    };

    const transition = { type: 'spring', duration: 3 };

    return (
        <div className="Testimonials" id='testimonials'>
            <div className="intro-text">
                <span>Testimonials</span>
                <span className='stroke-text'>What they</span>
                <span>say about us</span>
            </div>
            <div className="testimonial-content">
                <motion.span
                    key={selected}
                    initial={{ opacity: 0, x: 100 }} // Start from the right
                    animate={{ opacity: 1, x: 0 }}  // Move to the center
                    exit={{ opacity: 0, x: -100 }} // Exit to the left
                    transition={transition}
                    className='review-text'
                >
                    {testimonialsData[selected].review}
                </motion.span>
                <span className='review-author'>
                    <span style={{ color: '#b78532' }}>
                        {testimonialsData[selected].name}
                    </span>
                </span>
            </div>
            <div className="arrows">
                <img
                    onClick={() => handleManualChange('prev')}
                    src={leftArrow}
                    alt="Previous"
                />
                <img
                    onClick={() => handleManualChange('next')}
                    src={rightArrow}
                    alt="Next"
                />
            </div>
        </div>
    );
}

export default Testimonials;
