import React, { useEffect } from 'react';
import './Services.css';
import { programsData } from '../../data/programsData';
import Slider from 'react-slick';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Services = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 }
      });
    }
  }, [controls, inView]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  return (
    <motion.div className="Services" id="services" ref={ref} initial={{ opacity: 0, y: 50 }} animate={controls}>
      <div className="services-header">
        <span className="stroke-text">Explore</span>
        <span>Our</span>
        <span className="stroke-text">Services</span>
      </div>

      <div className="service-categories">
        <Slider {...settings}>
          {programsData.map((program, index) => (
            <motion.div className="category" key={index} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              {program.image}
              <span>{program.heading}</span>
              <span>{program.details}</span>
            </motion.div>
          ))}
        </Slider>
      </div>
    </motion.div>
  );
};

export default Services;