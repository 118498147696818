import React from "react";
import "./Home.css";
import Header from "../Header/Header";
import Typist from "react-typist";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import TypistLoop from "react-typist-loop";

const work = [
  "Confidence",
  "Glamour",
  "Sophistication",
  "Transformations",
  "Elegance",
  "Journey",
  "Empowerment"
];
const Home = () => {
  const transition = { type: "spring", duration: 3 };
  return (
    <div className="home" id="home">
      <div className="blur home-blur"></div>
      <div className="left-h">
        <Header />

        <div className="the-best-ad">
          {/* <motion.div
            initial={{ left: "180px" }}
            whileInView={{ left: "8px" }}
            transition={transition}
          ></motion.div> */}

          <span>The best Salon In the Town</span>
        </div>

        <div className="title-text">
          <div>
          <div style={{ display: 'flex' }}>
  <span className="red-storke">Crafting</span>&nbsp;
  <span className="red-storke"><TypistLoop interval={1000}>{work.map((text) => (<Typist key={text} startDelay={500}>{text}</Typist>))}</TypistLoop></span>
</div>

          </div>
          <div>
            <span>Where Style Meets Tradition</span>
          </div>
        </div>
        <div>
          <span></span>
        </div>
        <div>
          <span></span>
        </div>
        <div>
          <span></span>
        </div>
        {/* <div>
          <span></span>
        </div> */}

        {/* home buttons */}
        <div className="home-buttons">
          <button className="btn">
            <Link to="contact" span={true} smooth={true}>
              Get Started
            </Link>
          </button>
          <button className="btn">
            <Link to="services" span={true} smooth={true}>
              Know More
            </Link>
          </button>
        </div>
      </div>
      {/* <div className="right-h">
        <button className="btn">
          <Link to="contact" span={true} smooth={true}>
            Contact Us
          </Link>
        </button>
      </div> */}
    </div>
  );
};

export default Home;
