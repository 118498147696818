import React from 'react'
import './Footer.css'
import facebook from '../../assets/facebook.png'
import instagram from '../../assets/instagram.png'
import youtube from '../../assets/whatsapp.png'

const Footer = () => {
  return (
    <div className="Footer-container">
        <hr/>
        <div className="footer" id='footer'>
            <div className="social-links">
               <a href="https://www.facebook.com/share/TpzVsdTyXhfDTPka/?mibextid=qi2Omg"> <img src={facebook} alt="" /> </a>
               <a href="https://www.instagram.com/tgf.familysalon?igsh=ZjBobDVkeWloanpo"><img src={instagram} alt="" /></a>
               <a href="https://wa.me/918583999491"><img src={youtube} alt="" /></a>
            </div>
        
            <div className="logo-f">
               TGF - Family Salon
            </div>
        </div>
    </div>
    )
}

export default Footer