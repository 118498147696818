// import { useRef } from 'react'
import React from "react";
import "./ContactUs.css";
// import emailjs from '@emailjs/browser'
import "@fortawesome/fontawesome-free/css/all.css";

const ContactUs = () => {
  // const form = useRef()

  // const sendEmail = (e) => {

  //     e.preventDefault();

  //     emailjs
  //       .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, {
  //         publicKey: 'YOUR_PUBLIC_KEY',
  //       })
  //       .then(
  //         () => {
  //           console.log('SUCCESS!');
  //         },
  //         (error) => {
  //           console.log('FAILED...', error.text);
  //         },
  //       );
  //   };
  return (
    <div className="Contact" id="contact">
      <div className="left-c">
        <hr />
        <div>
          <span className="stroke-text">BE STYLISH</span>
          <span> BE SMART</span>
        </div>
        <div>
          <span>BOOK YOUR</span>
          <span className="stroke-text"> VISIT</span>
        </div>
      </div>
      <div className="right-c">
        {/* <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type="email" name='user-email' placeholder='Enter Your Phone Number' />
                    <button className='btn btn-c'>Send Now</button>
                </form> */}
        {/* <span>Phone - 9087654321</span>
                <span>email - test.gmail.com</span> */}
        <div class="contact_info_sec email-container">
          <h4>Contact Info</h4>
          <div class="d-flex info_single align-items-center">
            <i class="fas fa-headset"></i>
            <span>+91 8583 999491</span>
          </div>
          <div class="d-flex info_single align-items-center">
          <i class="fa-brands fa-whatsapp"></i>
          <a href="https://wa.me/918583999491" className="link" target="_blank"><span>wa.me</span></a>
          </div>
          <div className="d-flex info_single align-items-center">
  <i className="fas fa-envelope-open-text"></i>
  <a href="mailto:tgfsalon1@gmail.com" className="link"><span>tgfsalon1@gmail.com</span></a>
</div>



          <div class="d-flex info_single align-items-center">
            <a className="link"
              href="https://www.google.com/maps/dir//TGF+-+Family+Salon/@22.5153014,88.2624932,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x3a027101d65eeb25:0x51b48b20669c5f6e!2m2!1d88.3448948!2d22.5153224?entry=ttu"
              target="_blank"
            >
              <i class="fas fa-map-marked-alt"></i>
            </a>

            <span>
              TGF - Family Salon <br />
              <i> </i>Pratapaditya Rd, Near 27 Pally Park, Sahanagar,
              <br />
              <i> </i>Kalighat, Kolkata, West Bengal 700026
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
