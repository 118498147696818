import React from "react";
import "./Reasons.css";
import image1 from "../../assets/jeppe-monster-T_gTN3Po9RQ-unsplash.jpg";
import image2 from "../../assets/adam-winger-FkAZqQJTbXM-unsplash (1).jpg";
import image3 from "../../assets/rosa-rafael-Pe9IXUuC6QU-unsplash.jpg";
import image4 from "../../assets/jonathan-weiss-arxAZJT5k2A-unsplash.jpg";
import tick from "../../assets/blue-tick.png";

const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
      <div className="left-r">
        {/* Displaying all images for desktop and tablet view */}
        <img src={image1} alt="hair style images" />
        <img src={image2} alt="hair style images" />
        <img src={image3} alt="hair style images" />
        <img src={image4} alt="hair style images" />
      </div>
      <div className="right-r">
        <span>Some reasons</span>

        <div>
          <span className="stroke-text">Why </span>
          <span>choose us?</span>
        </div>

        <div className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>EXPERT TEAM</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>DIVERSE SERVICES</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>HAPPY CLIENT</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>UNIQUE ATMOSPHERE</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reasons;
