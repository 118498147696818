import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-scroll';
import Logo from '../../assets/logo.png';

const Header = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="header">
        <img src={Logo} alt='Logo' className='logo' />

        <ul className={`header-menu ${isSidebarOpen ? 'hide' : ''}`}>
          <li><Link to='home' span={true} smooth={true}>Home</Link></li>
          <li><Link to='services' span={true} smooth={true}>Services</Link></li>
          <li><Link to='reasons' span={true} smooth={true}>Why Us</Link></li>
          <li><Link to='testimonials' span={true} smooth={true}>Testimonials</Link></li>
        </ul>

        <button className='contact-btn'>
          <Link to="contact" span={true} smooth={true}>
            Contact Us
          </Link>
        </button>

        <button className='menu-btn' onClick={toggleSidebar}>
          ☰
        </button>
      </div>

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <span className='close-btn' onClick={toggleSidebar}>✖</span>
        <ul className='sidebar-menu'>
          <li><Link to='home' span={true} smooth={true}>Home</Link></li>
          <li><Link to='services' span={true} smooth={true}>Services</Link></li>
          <li><Link to='reasons' span={true} smooth={true}>Why Us</Link></li>
          <li><Link to='testimonials' span={true} smooth={true}>Testimonials</Link></li>
          <li><Link to='contact' span={true} smooth={true}>Contact Us</Link></li>
        </ul>
        {/* <div className='sidebar-buttons'>
          <button className='btn'>
            <Link to="contact" span={true} smooth={true}>
              Contact Us
            </Link>
          </button>
        </div> */}
      </div>
    </>
  );
};

export default Header;
